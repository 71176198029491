import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material'
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom'
// import App from './App'
const App = lazy(() => import('./App'));

// import './index.css'


const theme = createTheme({
  typography: {
    fontFamily: 'Fira Sans, Helvetica, Arial, sans-serf',
  }
})


const rootElement = document.getElementById('root')
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  rootElement
)
